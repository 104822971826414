<template>
  <div class="dropdown-main-div">
    <v-select
      dense
      solo
      flat
      height="29px"
      v-model="selectedYear"
      :items="fiscalYearList"
      item-value="id"
      :hide-details="true"
      append-icon="$purpleDownArrow"
      background-color="#D8D5FC"
      @input="updatedSelection"
    >
      <template v-slot:item="{ item }">
        <span class="primary--text pr-4 font-16px">{{
          `${item.startYear}年度`
        }}</span>
        <span class="primary--text font-14px">
          {{ `${item.startRange} ～ ${item.endRange}` }}
        </span>
      </template>
      <template v-slot:selection="{ item }">
        <span class="primary--text pr-4 font-16px">{{
          `${item.startYear}年度`
        }}</span>
        <span class="primary--text font-14px">
          {{ `${item.startRange} ～ ${item.endRange}` }}
        </span>
      </template>
    </v-select>
  </div>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "FiscalYearDropDown",
  props: {
    removeFirst: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedYear: null,
      startYear: dayjs().year(),
      fiscalYearList: null
    };
  },
  created() {
    let currentMonth = dayjs().month() + 1;
    if (currentMonth < this.getShop.start_of_fiscal_year) {
      --this.startYear;
    }
    this.generateFiscalYearList();
    this.selectedYear = this.fiscalYearList[0];
    this.updatedSelection(this.selectedYear);
  },
  computed: {
    ...mapGetters(["getShop"])
  },
  methods: {
    generateFiscalYearList() {
      let yearsList = [];
      let current = dayjs(
        `${this.startYear}-${this.getShop.start_of_fiscal_year}-01`,
        "YYYY-M-DD"
      );

      for (let i = 0; i < 3; i++) {
        let temp = {};
        temp.startYear = current.year();
        temp.startRange = `${current.year()}/${current.format("MM")}`;
        let next = current.add(11, "month");
        temp.endRange = `${next.year()}/${next.format("MM")}`;
        temp.id = i;
        yearsList.push(temp);
        current = current.subtract(1, "year");
      }
      if (this.removeFirst) {
        yearsList.splice(0, 1);
      }
      this.fiscalYearList = yearsList;
    },
    updatedSelection(data) {
      let year = data;
      if (typeof data !== "object")
        year = this.fiscalYearList.find(item => item.id === data);
      this.$emit("updatedSelectedYear", year.startYear, data);
    }
  }
};
</script>
<style lang="scss" scoped>
.dropdown-main-div {
  width: 261px !important;
  ::v-deep .v-input__control {
    min-height: 29px !important;
  }
  ::v-deep .v-input__slot {
    min-height: 29px !important;
    color: #7367f0 !important;
  }
}
</style>
