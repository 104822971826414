var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown-main-div"
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "solo": "",
      "flat": "",
      "height": "29px",
      "items": _vm.fiscalYearList,
      "item-value": "id",
      "hide-details": true,
      "append-icon": "$purpleDownArrow",
      "background-color": "#D8D5FC"
    },
    on: {
      "input": _vm.updatedSelection
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "primary--text pr-4 font-16px"
        }, [_vm._v(_vm._s("".concat(item.startYear, "\u5E74\u5EA6")))]), _c('span', {
          staticClass: "primary--text font-14px"
        }, [_vm._v(" " + _vm._s("".concat(item.startRange, " \uFF5E ").concat(item.endRange)) + " ")])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "primary--text pr-4 font-16px"
        }, [_vm._v(_vm._s("".concat(item.startYear, "\u5E74\u5EA6")))]), _c('span', {
          staticClass: "primary--text font-14px"
        }, [_vm._v(" " + _vm._s("".concat(item.startRange, " \uFF5E ").concat(item.endRange)) + " ")])];
      }
    }]),
    model: {
      value: _vm.selectedYear,
      callback: function callback($$v) {
        _vm.selectedYear = $$v;
      },
      expression: "selectedYear"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }